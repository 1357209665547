

























































































































































































































































































































































































































































































.v-date-range__input-field::v-deep input {
  text-align: center;
}

/* =============================================
=            Menu Content            =
============================================= */
.v-date-range__content {
  display: flex;

  @media (max-width: 960px) {
      flex-direction: column;
  }

  >>> .v-date-picker-table {
    .v-btn {
      border-radius: 0;
    }
  }
}

.v-date-range__content::v-deep .v-date-picker-table {
  .v-btn {
    border-radius: 0;
  }
}

/* =====  End of Menu Content  ====== */
.v-date-range__pickers::v-deep .v-date-picker-table__events {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

/* =============================================
=            Date buttons            =
============================================= */
.v-date-range__pickers::v-deep .v-date-picker-table table {
  width: auto;
  margin: auto;
  border-collapse: collapse;

  & th, & td {
    height: 32px;
    width: 32px;
  }

  & td {
    .v-btn {
      &.v-btn--outline {
        border: none;
        box-shadow: 0 0 0 1px currentColor inset;
      }

      &.v-btn--active::before {
        background-color: transparent !important;
      }
    }
  }
}

/* =====  End of Date buttons  ====== */
/* =============================================
=            Highlighting the even bubble dot            =
============================================= */
.v-date-range__pickers::v-deep .v-date-range__in-range {
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.v-date-range__pickers::v-deep .v-picker__body {
  max-width: 100%;
}

.menu-range-date {

}

/* =====  End of Highlighting the even bubble dot  ====== */
